
import { Vue, Component } from 'vue-property-decorator';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import { UserRepository } from '@/repositories/UserRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import ClientsTableWrapper from '@/components/views/ClientView/ClientsTableWrapper.vue';
import TableSearchBar from '@/components/global/TableSearchBar.vue';
import ClientTopBar from '../components/views/ClientView/ClientTopBar.vue';
import FloatingDeleteActions from '../components/views/home/FloatingDeleteActions.vue';
import Project from '@/models/Project';
import { PopupEventData } from '@/interfaces/components/PopupEventData';
import { PopupEvents } from '@/enums/global/PopupEvents';
import { RouteNames } from '@/enums/routes/RouteNames';
import Client from '@/models/Client';
import { AxiosResponse } from 'axios';
import ClientRepository from '@/repositories/ClientRepository';
import ProjectRepository from '@/repositories/ProjectRepository';
import { ErrorOptions } from '@/interfaces/ErrorOptions';

@Component({
    name: 'ClientView',
    components: {
        ClientsTableWrapper,
        TableSearchBar,
        ClientTopBar,
        FloatingDeleteActions,
    },
})
export default class ClientView extends Vue {
    private search = {
        isActive: true,
    };
    private numberOfTotalItems = 0;
    private isDeleteMode: boolean = false;
    private selectedClients: string[] = [];
    private deletingClients = false;
    private clientsDeleted = 0;
    private progressStatus = 'active';
    private totalClients = 0;
    private shouldCancelDeletingOnClose = false;

    private get progressBarPercentage() {
        return Math.round((this.clientsDeleted / this.totalClients) * 100);
    }

    private get searchMessage() {
        if (this.numberOfTotalItems <= 0) {
            return this.$t('Nije pronađen niti jedan rezultat');
        }

        if (this.numberOfTotalItems === 1) {
            return this.$t('Pronađen jedan rezultat');
        }

        return (this.$t('Pronađeno je ukupno __NUMBEROFTOTALITEMS__ rezultata') as string).replace(
            '__NUMBEROFTOTALITEMS__',
            String(this.numberOfTotalItems)
        );
    }

    private get users() {
        return CMSUserRepository.getAll();
    }

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }

    private onUpdatePagination({ totalNumber }: { totalNumber: number }) {
        if (totalNumber == null) {
            return;
        }

        this.numberOfTotalItems = totalNumber;
    }
    private startTableLoading() {
        const wrapper = this.$refs.clientsTableWrapper as any;
        if (wrapper && wrapper.startLoading) {
            wrapper.startLoading();
        }
    }

    private onUpdateIsDeleteMode(data: { isDeleteMode: boolean; selectedRows: string[] }) {
        this.isDeleteMode = data.isDeleteMode;

        if (this.isDeleteMode && data.selectedRows) {
            this.selectedClients = data.selectedRows;
        } else {
            this.selectedClients = [];
        }
    }

    private resetDeletingStateWatchers(
        progressStatus: string = 'active',
        isDeletingClients: boolean = false,
        isDeleteMode: boolean = false
    ) {
        this.deletingClients = isDeletingClients;
        this.progressStatus = progressStatus;
        this.clientsDeleted = 0;
        this.isDeleteMode = isDeleteMode;
        this.shouldCancelDeletingOnClose = true;
    }

    private prepareDeletingClients() {
        this.resetDeletingStateWatchers('active', true, true);
        this.totalClients = this.selectedClients.length;

        this.evaluateAndDeleteSelectedClients();
    }

    private async evaluateAndDeleteSelectedClients(lastDeletedClientID?: string) {
        // We delete first selected client and emit this fun
        // for deleting next selected client if there is any client ID
        // and delete clients and reasign contact person
        // if client has projects

        if (lastDeletedClientID) {
            this.removeClientFromTableAndSelectedList(lastDeletedClientID);
        }

        if (!this.deletingClients) {
            return;
        }

        if (!this.selectedClients.length) {
            this.progressStatus = 'success';

            setTimeout(() => {
                this.resetDeletingStateWatchers();
            }, 500);

            if (this.clientsDeleted > 0) {
                this.$notification.success({
                    message: this.$t('Clients successfully deleted') as string,
                    description: '',
                });
            }
            return;
        }

        const clientId = this.selectedClients[0];

        // Fetch client
        await Client.getById(clientId);
        const client = ClientRepository.getById(clientId);

        if (!client) {
            this.resetDeletingStateWatchers('active');

            this.$notification.error({
                message: this.$t('Client not found') as string,
                description: '',
            });
            return;
        }

        await this.onDeleteClient(clientId, client);
    }

    private removeClientFromTableAndSelectedList(lastDeletedClientID: string) {
        this.selectedClients.splice(
            this.selectedClients.findIndex((id) => id === lastDeletedClientID),
            1
        );
        EventBus.$emit(EventBusEvents.removeDeletedClientFromTable, lastDeletedClientID);
    }

    private async onDeleteClient(clientId: string, client: Client) {
        await Project.getByClient(clientId);
        const projects = ProjectRepository.filterProjectsByClient(clientId);

        if (projects.length) {
            const popupData: PopupEventData = {
                popupEvent: PopupEvents.deleteClientPerson,
                data: {
                    clientId,
                    client,
                    deleteClient: this.deleteClientAndClosePopup.bind(this),
                    isMultipleClientDeletion: true,
                },
            };
            EventBus.$emit(EventBusEvents.onDeleteClientPerson, popupData);
        } else {
            await this.deleteClientWithoutProjects(clientId);
        }
    }

    private async deleteClientWithoutProjects(clientId: string) {
        try {
            await this.deleteClient(clientId);
        } catch (error) {
            const errorObj = error as ErrorOptions;
            this.resetDeletingStateWatchers('exception');
            this.$notification.error({
                message: this.$t('Error deleting clients') as string,
                description: errorObj.response ? errorObj.response.data.meta.message : errorObj.message,
            });
            return;
        }

        this.clientsDeleted++;
        this.evaluateAndDeleteSelectedClients(clientId);
        return Promise.resolve();
    }

    private async deleteClientAndClosePopup(params: any) {
        const clientId = params.clientId as string;
        const projects = params.projects as Project[];
        const newClientId = params.newClientId ? (params.newClientId as string) : null;
        const newContactPersonId = params.newContactPersonId ? (params.newContactPersonId as string) : null;

        try {
            if (projects.length && newClientId && newContactPersonId) {
                // tslint:disable
                const updateCalls: Promise<AxiosResponse<any>>[] = [];

                projects.forEach((project: Project) => {
                    updateCalls.push(
                        Project.updateClientAndContactPerson({
                            projectId: project.id,
                            clientId: newClientId,
                            contactPersonId: newContactPersonId,
                        })
                    );
                });

                await Promise.all(updateCalls);
            }

            await this.deleteClient(clientId);
        } catch (error) {
            const errorObj = error as ErrorOptions;
            this.resetDeletingStateWatchers('exception');
            this.$notification.error({
                message: this.$t('Error deleting clients') as string,
                description: errorObj.response ? errorObj.response.data.meta.message : errorObj.message,
            });
            return;
        }

        // Prevent canceling deleting on closing popup at this moment,
        // but allow canceling on manual closing
        this.shouldCancelDeletingOnClose = false;
        EventBus.$emit(EventBusEvents.closePopup);
        await this.$nextTick();

        this.shouldCancelDeletingOnClose = true;

        this.clientsDeleted++;
        this.evaluateAndDeleteSelectedClients(clientId);
        return Promise.resolve();
    }

    private async deleteClient(clientId: string) {
        try {
            await Client.deleteExisting(clientId);
        } catch (error) {
            return Promise.reject(error);
        }
        return Promise.resolve();
    }

    private cancelDeletingClients() {
        if (this.shouldCancelDeletingOnClose) {
            this.resetDeletingStateWatchers('active', false, true);
        }
    }

    private async mounted() {
        EventBus.$on(EventBusEvents.updateIsDeleteMode, this.onUpdateIsDeleteMode);
        EventBus.$on(EventBusEvents.emitClientPagination, this.onUpdatePagination);
        EventBus.$on(EventBusEvents.cancelDeletingClients, this.cancelDeletingClients);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.updateIsDeleteMode, this.onUpdateIsDeleteMode);
        EventBus.$off(EventBusEvents.emitClientPagination, this.onUpdatePagination);
        EventBus.$off(EventBusEvents.cancelDeletingClients, this.cancelDeletingClients);
    }
}
